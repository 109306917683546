<template>
  <div class="Zixun">
    <div class="Zixunbox">
      <div class="Zixun_l">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          v-loading="loading"
        >
          <el-tab-pane
            v-for="item in eltabpane"
            :key="item.name"
            :label="item.label"
            :name="item.name"
          >
            <ul class="Zixunul">
              <li
                v-for="item in datalist"
                :key="item.id"
                @click="titleclick(item.id)"
                class="Zixunulli"
              >
                <div class="Zixunulli_l">
                  <img v-lazy="item.titlepic" alt="" />
                </div>
                <div class="Zixunulli_r">
                  <p class="Zixunulli_rtitle">{{ item.title }}</p>
                  <div class="Zixunulli_rtext">{{ item.smalltext }}</div>
                  <div class="Zixunulli_rcenter">
                    <icon-svg icon-class="iconlook" />
                    <span> {{ item.onclick }}</span>
                    <span>{{ item.create_time }}</span>
                    <span>{{ item.classid }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
        <el-pagination
          @current-change="currentchange"
          :page-size="pagesize"
          background
          layout="prev, pager, next"
          :total="total"
          :pager-count="5"
        >
        </el-pagination>
      </div>
      <div class="Zixun_r" id="Zixun_r">
        <div class="Zixun_rtitle">推荐资讯</div>
        <ul class="Zixun_rul">
          <li
            v-for="(item, index) in Zixun_tj"
            :key="item.id"
            @click="titleclick(item.id)"
          >
            <span>0{{ index + 1 }}</span>
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import $ from "jquery";

export default {
  components: {},
  data() {
    return {
      loading: true,
      activeName: "zero",
      id: "",
      paths: "/Zixun_C",
      total: 0,
      eltabpane: [
        {
          label: "全部",
          name: "zero",
        },
        {
          label: "平台新闻",
          name: "11",
        },
        {
          label: "专业动态",
          name: "12",
        },
        {
          label: "政策法规",
          name: "13",
        },
        {
          label: "平台规则",
          name: "14",
        },
        {
          label: "平台公告",
          name: "15",
        },
      ],
      datalist: [],
      pagesize: 10,
      Zixun_tj: [],
      userinfo: {},
    };
  },
  created() {
    this.fenxiang(
      "和作营-平台资讯",
      "平台资讯",
      "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
    );
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.currentchange(1);
    this.$axios({
      url: "/api/getPushNews",
      method: "post",
      data: {
        page: "1",
        limit: "5",
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.Zixun_tj = res.data.result.list;
      } else {
        console.log("请求失败");
      }
    });
  },
  methods: {
    handleClick(tab, event) {
      var that = this;
      that.loading=true;
      that.datalist = "";
      that.id = tab.paneName;
      if (that.id == "zero") {
        that.id = "";
      }
      console.log(that.id);
      this.$axios({
        url: "/api/getNews",
        method: "post",
        data: {
          news_type: that.id,
          page: "1",
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.loading=false;
          console.log(res);
          that.total = res.data.result.count;
          var data = res.data.result.list;
          that.datalist = data;
          for (var i = 0; i < data.length; i++) {
            if (data[i].classid == "11") {
              that.datalist[i].classid = "平台新闻";
            } else if (data[i].classid == "12") {
              that.datalist[i].classid = "专业动态";
            } else if (data[i].classid == "13") {
              that.datalist[i].classid = "政策法规";
            } else if (data[i].classid == "14") {
              that.datalist[i].classid = "平台规则";
            } else if (data[i].classid == "15") {
              that.datalist[i].classid = "平台公告";
            }
          }
        } else {
          console.log("请求失败");
        }
      });
    },
    titleclick(id) {
      var that = this;
      this.$router.push({ path: "/Zixun_C", query: { data: id } });
    },
    currentchange(index) {
      document.documentElement.scrollTop = 0;
      var that = this;
      that.loading=true;
      this.$axios({
        url: "/api/getNews",
        method: "post",
        data: {
          news_type: that.id,
          page: index,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.loading=false;
          var data = res.data.result.list;
          that.total = res.data.result.count;
          that.datalist = data;
          for (var i = 0; i < data.length; i++) {
            if (data[i].classid == "11") {
              that.datalist[i].classid = "平台新闻";
            } else if (data[i].classid == "12") {
              that.datalist[i].classid = "专业动态";
            } else if (data[i].classid == "13") {
              that.datalist[i].classid = "政策法规";
            } else if (data[i].classid == "14") {
              that.datalist[i].classid = "平台规则";
            } else if (data[i].classid == "15") {
              that.datalist[i].classid = "平台公告";
            }
          }
        } else {
          console.log("请求失败");
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      console.log(to.path);
    },
  },
  mounted() {
    window.onresize = () => {
      var divr_r = $("#Zixun_r").offset().left; //距离左边距离
      var t1 = document.documentElement.scrollTop || document.body.scrollTop; //滚动条 距顶距离 1167
      if (t1 > 103) {
        $("#Zixun_r").css({
          position: "fixed",
          width: "25rem",
          top: "0",
          left: divr_r,
        });
      } else {
        $("#Zixun_r").css({
          position: "relative",
          left: "0",
        });
      }
    };
    document.onscroll = function () {
      var divr_r = $("#Zixun_r").offset().left; //距离右边距离
      var t1 = document.documentElement.scrollTop || document.body.scrollTop; //滚动条 距顶距离 1167
      if (t1 > 103) {
        $("#Zixun_r").css({
          position: "fixed",
          width: "25rem",
          top: "0",
          left: divr_r,
        });
      } else {
        $("#Zixun_r").css({
          position: "relative",
          left: "0",
        });
      }
    };
  },
};
</script>
<style>
@import "../assets/css/zixun.css";
</style>
